const references = {
  countries: [
    { id: 1, code: "CH", country: "Switzerland", length: 4, house_types: [] },
    { id: 2, code: "LI", country: "Liechtenstein", length: 4, house_types: [] },
    { id: 3, code: "DE", country: "Germany", length: 5, house_types: [] },
    {
      id: 4,
      code: "AT",
      country: "Austria",
      length: 4,
      house_types: ["BEEHOME", "BEEHOME_OBSERVER"],
    },
  ],
  languages: [
    { id: 1, code: "de", name: "Deutsch" },
    { id: 2, code: "fr", name: "French" },
  ],
  house_types: [
    {
      code: "OBSERVER",
      name: "BeeHome Observer",
      image: "images/houses/observer.jpg",
      isEnabled: false,
      isDefault: false,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
    {
      code: "BEEHOME_OBSERVER",
      name: "BeeHome Observer",
      image: "images/houses/beehome_observer.jpg",
      isEnabled: false,
      isDefault: false,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
    {
      code: "CLASSIC",
      name: "BeeHome Classic",
      image: "images/houses/classic.jpg",
      isEnabled: false,
      isDefault: false,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
    {
      code: "DIVERSITY",
      name: "BeeHome Diversity",
      image: "images/houses/pro.jpg",
      isEnabled: false,
      isDefault: false,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
    {
      code: "PRO",
      name: "BeeHome Pro",
      image: "images/houses/beehome_pro_ch.png",
      isEnabled: false,
      isDefault: false,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
    {
      code: "BEEHOME_PRO",
      name: "BeeHome Pro",
      image: "images/houses/beehome_pro_defr.png",
      isEnabled: false,
      isDefault: false,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
    {
      code: "ONE",
      name: "BeeHome One",
      image: "images/houses/one.jpg",
      isEnabled: false,
      isDefault: false,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
    {
      code: "BEEHOME",
      name: "BeeHome",
      image: "images/houses/beehome.jpg",
      isEnabled: false,
      isDefault: false,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
    {
      code: "UNKNOWN",
      name: "BeeHome Unknown",
      image: "images/houses/unknown.jpg",
      isEnabled: true,
      isDefault: true,
      regex:
        /^[0-9]{2}[A-Z][0-9]{4}$|^[0-9]{2}[MO][0-9]{6}$|^[AXP][0-9]{2}(DE|CH|FR|IT)[0-9A-Z]{4,5}$/,
    },
  ],
  out_of_season_messages: {
    beecare: {
      preSeason: {
        title: "510",
        message: "509",
        urlName: "509-link",
      },
      postSeason: {
        title: "281",
        message: "282",
        urlName: "282-url",
      },
    },
    startpop: {
      preSeason: {
        title: "Startpop orders are closed",
        message: "The flying season has not yet started",
        urlName: "preSeasonLink",
      },
      postSeason: {
        title: "Startpop orders are closed",
        message: "200",
        urlName: "200-link",
        urlNameB: "200-link-b",
      },
      austriaOutOfSeason: {
        // title: "Startpop orders are closed",
        message: "506",
        urlName: "postSeasonLink",
      },
    },
  },
  numbers: {
    de: {
      1: " einen",
      2: " zwei",
      3: " drei",
      4: " vier",
      5: " fünf",
      6: " sechs",
      7: " sieben",
      8: " acht",
      9: " neun",
      10: " zehn",
    },
    fr: {
      1: " un",
      2: " deux",
      3: " trois",
      4: " quatre",
      5: " cinq",
      6: " six",
      7: " sept",
      8: " huit",
      9: " neuf",
      10: " dix",
    },
  },
  defaultOrders: {
    donationOptions: {
      ten: { value: 10, selected: false },
      twenty: { value: 20, selected: false },
      fifty: { value: 50, selected: false },
    },
    startpop: {
      minimum_amount: 1,
      minimum_price: 5,
      priceText: {
        cornuta: {
          makePrice: "408",
          otherPeopleGave: "409",
        },
        bicornis: {
          makePrice: "103",
          otherPeopleGave: "105",
        },
      },
      warningTexts: {
        title: "413",
        warningText: "414",
        checkboxCloseText: "415",
      },
    },
    beecare: {
      address_id: 0,
      type: "BEECARE",
      houses: [],
      vouchers: [],
      selectedHouses: [],
      discount_vouchers: [],
      variant: null,
      delivery_on: "",
      donation: 0,
      currency: "CHF",
      price: 39,
      priceReduction: 0,
      total_price: 0,
      amount: 0,
      psp: "INVOICE",
    },
  },
  allowedRegex: {
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    // /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,7}$|^[a-zA-Z0-9!@#$%^&*]{8,16}$/,
    standard: /^[^*{}[\]=><\\]{1,25}$/,
    address1: {
      CH: /^[^*{}[\]=><\\]{1,25}$/,
      LI: /^[^*{}[\]=><\\]{1,25}$/,
      AT: /^[^*{}[\]=><\\]{1,35}$/,
      DE: /^[^*{}[\]=><\\]{1,35}$/,
    },
    streetNumber: /.*[0-9].*/,
    postalCode: /^[0-9]{4,5}$/,
  },
  variants: {
    beecare: [
      {
        minimum: 1,
        price: 39,
      },
      {
        minimum: 2,
        price: 33,
      },
      {
        minimum: 3,
        price: 31,
      },
      {
        minimum: 4,
        price: 29,
      },
    ],
  },
};

export default references;
